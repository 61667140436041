<template>
  <div class="table_wrapper">
    <table class="table cart-list menu-gallery">
      <thead>
        <tr>
          <th>Produsul</th>
          <th>Pretul</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(entry, index) in products" :key="index">
          <td class="d-md-flex align-items-center">
            <figure>
              <b-img :src="entry.image_url"></b-img>
            </figure>
            <div class="flex-md-column">
              <h4 v-if="entry.qty">{{ entry.qty }} x {{ entry.name }}</h4>
              <h4 v-else>{{ entry.name }}</h4>
              <div v-if="entry.selected_features">
                <div v-for="(feature, ind) in entry.selected_features" :key="ind">
                  <div v-for="(opt, ix) in feature.options" :key="ix">
                    <div v-if="opt.checked">
                      <div v-if="!opt.multiple">
                        <strong>{{ feature.label }}</strong> {{ 1 * entry.qty }} x {{ opt.label }}
                        <span class="price">{{ toCurrency(opt.price) }}</span>
                      </div>
                      <div v-else-if="opt.multiple">
                        <strong>{{ feature.label }}</strong>: {{ opt.qty }} x {{ opt.label }}
                        <span class="price">{{ toCurrency(opt.price * opt.qty) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            <strong>{{ toCurrency(entry.price * entry.qty) }}</strong>
          </td>
        </tr>
      </tbody>
      

    </table>
  </div>
</template>

<script>
export default {
  name: 'ProductsDetails',
  props: {
    products: {
      type: Array
    }
  }
} 
</script>
