<template>
  <div class="box_order_form">
    <div class="head text-center" :class="orderStatusesBackgroundColors[orderData.status]">
      <h3>Comanda #{{ orderData.id }} ({{ formatDateTime(orderData.created_at) }})</h3>
      <h3>Status: {{ orderStatus(orderData.status) }}</h3>
      <b-alert class="p-1 text-center mt-2" v-if="orderData.preorder_date" show variant="danger"><strong>Precomanda la:</strong> {{orderData.preorder_date}}</b-alert>
      <b-alert class="p-1 text-center" v-if="orderData.paid_with_card" show variant="success">Achitata folosind cardul {{ orderData.card_payment_details.brand }} cu terminatia {{ orderData.card_payment_details.last4 }} </b-alert>
      <b-alert class="p-1 text-center" v-else show variant="primary">METODA DE PLATA: CASH</b-alert>
      <h3 v-if="orderData.status !== 0 && orderData.status !== 4 && orderData.status !== 5 && orderData.preorder_date === null">Livrare estimata: {{ addMinutesToDate(orderData.updated_at, orderData.est_customer_time_min + orderData.est_restaurant_time_min + orderData.est_driver_time_to_customer_min) }}</h3>
      <h3 v-if="orderData.status === 4">Livrata la: {{ formatDateTime(orderData.updated_at) }}</h3>
    </div>
    <!-- /head -->
    <div class="main">
      <b-row class="text-center" align-v="center">
        <b-col>
          <div class="detail_page_head clearfix">
            <div class="title">
              <h1>{{ orderData.restaurant.name }}</h1>
              {{ orderData.restaurant.street }}, {{ orderData.restaurant.zip }} {{ orderData.restaurant.city }}<br>
              <a :href="'tel:'+orderData.restaurant.telefon">{{ orderData.restaurant.telefon }}</a>
            </div>
          </div>
        </b-col>
        <b-col>
          <b-img rounded fluid :src="orderData.restaurant.image_url" :alt="orderData.restaurant.name" style="max-height: 200px"></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <h4>Produse comandate</h4>
          <ProductsDetails
            :products=orderData.products 
          />
        </b-col>
        <b-col md="12">
          <b-row class="text-center">
            <b-col cols="6">
              <h5>Detalii livrare</h5>
              <div class="detail_page_head pt-4 clearfix">
                <h6>{{ orderData.order_details.firstName }} {{ orderData.order_details.lastName }}</h6>
                <span>{{ orderData.order_details.street }} {{ orderData.order_details.address_extra }}, {{ orderData.order_details.zip }} {{ orderData.order_details.city }}</span>
                <p>{{ orderData.order_details.phone }}</p>
              </div>
            </b-col>
            <b-col cols="6">
              <h5>Detalii plata</h5>
              <div class="detail_page_head pt-4 clearfix">
                <h6 v-if="!orderData.voucher">Total de plata: {{ toCurrency(orderData.order_total + orderData.delivery_tax) }}</h6>
                <h6 v-if="orderData.voucher">
                  Total de plata: {{ toCurrency(orderData.order_total + orderData.delivery_tax - calculateVoucherAmount(orderData)) }}
                </h6>
                <span>Produse: {{ toCurrency(orderData.order_total) }}</span>
                <p class="mb-0">Livrare: {{ toCurrency(orderData.delivery_tax) }}</p>
                <p v-if="orderData.voucher && orderData.voucher.value_type === 'fixed'" class="text-danger">
                  Cupon reducere: -{{ toCurrency(orderData.voucher.value) }}
                </p>
                <p v-if="orderData.voucher && orderData.voucher.value_type === 'percent'" class="text-danger">
                  Cupon reducere: -{{ toCurrency(calculateVoucherAmount(orderData)) }} ({{ orderData.voucher.value }} %)
                </p>
              </div>
            </b-col>
            <b-col cols="12" v-if="orderData.order_details.customer_notes">
              <h4>Mentiuni comanda</h4>
              <div class="detail_page_head clearfix">
                <p>{{ orderData.order_details.customer_notes }} </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ProductsDetails from '@/components/shared/productsDetails'

const moment = require('moment')
export default {
  components: {
    ProductsDetails
  },
  props: {
    orderData: {
      type: Object,
      required: true,
      default: function() {
        return {
          restaurant: {
            name: ''
          },
          order_details: {
            firstName: ''
          }
        }
      }
    }
  }
}
</script>