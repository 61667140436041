<template>
  <main class="bg_gray" style="padding-top: 150px;">
    <b-container>
      <b-row>
        <b-col md="3">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>MENIU UTILIZATOR</h3>
            </div>
            <div class="impersonated" v-if="impersonatedUsers">
              <b-row>
                <b-col md="12" class="p-1" v-for="(user, index) in impersonatedUsers" :key="index">
                  <b-button @click="backToUser(user)" block class="w-100" variant="primary"><i class="mdi mdi-exit-run"></i> {{ user.user_data.email }}</b-button>
                </b-col>
              </b-row>
            </div>
            <!-- /head -->
            <div class="main">
              <div>
                <b-nav pills vertical class="text-center">
                  <b-nav-item :active="$route.name === 'customer.orders.last'" :to="{ name: 'customer.orders.last' }">Ultima comanda</b-nav-item>
                  <b-nav-item :active="$route.name === 'customer.orders'" :to="{ name: 'customer.orders' }">Toate comenzile</b-nav-item>
                  <b-nav-item :active="$route.name === 'customer.account'" :to="{ name: 'customer.account' }">Informatii cont</b-nav-item>
                </b-nav>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'customer.orders.last'">
          <OrderDetails :orderData="orders[0]" v-if="orders.length > 0" />
          <span v-else>
            <b-alert show variant="info">Nu exista comenzi</b-alert>
          </span>
        </b-col>
        <b-col md="9" v-if="$route.name === 'customer.orders'">
          <b-row v-if="orders.length > 0">
            <b-col md="6" sm="12" v-for="(order, index) in orders" :key="index">
              <OrderDetails :orderData="order" :key="index" />
            </b-col>
          </b-row>
          <span v-else>
            <b-alert show variant="info">Nu exista comenzi</b-alert>
          </span>
          <b-row>
            <b-col cols="12">
              <b-pagination
                  v-if="orders.length > 0"
                  v-model="paging.page"
                  :total-rows="paging.total"
                  :per-page="paging.per_page"
                  @input="changePage"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import OrderDetails from "./OrderDetails";
import Orders from '@/components/publicEndpoints/orders'
import { bus } from "../../main";

export default {
  data() {
    return {
      orders: [],
      paging: {
        page: 1,
        per_page: 10,
        total: 10
      }
    }
  },
  computed: {
    impersonatedUsers() {
      let impersonatedUsers = localStorage.getItem('impersonated');
      if (impersonatedUsers) {
        return JSON.parse(impersonatedUsers)
      }
      return false;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.getOrders()
      }
    }
  },
  methods: {
    backToUser(user) {
      localStorage.setItem('token', user.token)
      localStorage.setItem('userInfo', JSON.stringify(user.user_data))
      if (user.user_data.role === 'restaurant-manager') {
        this.$router.push({ name: 'restaurant-manager.dashboard' })
      } else if (user.user_data.role === 'city-manager') {
        let impersonatedUsers = JSON.parse(localStorage.getItem('impersonated'));
        let newImpersonated = [];
        impersonatedUsers.forEach(user => {
          if (user.user_data.role !== 'city-manager') {
            newImpersonated.push(user);
          }
        })
        localStorage.setItem('impersonated', JSON.stringify(newImpersonated))
        this.$router.push({name: 'city-manager.dashboard'})
      } else if (user.user_data.role === 'driver') {
        this.$router.push({ name: 'driver.dashboard' })
      } else if (user.user_data.role === 'customer') {
        this.$router.push({ name: 'customer.account' })
      } else if (user.user_data.role === 'administrator') {
        this.$router.push({ name: 'admin.dashboard' })
        localStorage.removeItem('impersonated')
      }
      bus.$emit('loggedIn', user.user_data);
    },
    getOrders() {
      Orders.getAll(this.paging).then(response => {
        this.orders = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getOrders()
    }
  },
  components: {
    OrderDetails
  },
  mounted() {
    bus.$on('customer_update_order', () => {
      this.getOrders()
    })
    this.getOrders()
  }
}
</script>