import axios from '@/components/plugins/axios'
const url = process.env.VUE_APP_API_URL

class Orders {
  get (id) {
    return axios.get(url + '/public/orders/' + id)
  }

  getAll (params) {
    let options = '?page=' + params.page + '&per_page=' + params.per_page
    if (typeof (params.search) !== 'undefined') {
      options += '&search=' + params.search
    }
    return axios.get(url + '/public/orders' + options)
  }

  store (data) {
    return axios.post(url + '/public/orders', data)
  }

  addVoucher(code, restaurantId) {
    return axios.get(url + '/public/vouchers/' + code + '/' + restaurantId)
  }
}
export default new Orders()
